<template>
  <div :class="[topTheme=='black'?'':'whiteTheme']">
    <div v-if="!this.$util.isMobile()" class="topContent">
      <div class="content">
        <div class="logo"  @click="handleSelect('1')">
          <img :src="topTheme=='black'?logoIconBlack:logoIcon">
        </div>
        <div class="menu">
          <el-menu :default-active="activeIndex" :text-color="topTheme=='black'?'#FFFFFF':'#343434'" :background-color="topTheme=='black'?'transparent':'#FFFFFF'" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">解决方案</el-menu-item>
            <el-menu-item index="3">产品中心</el-menu-item>
            <el-menu-item index="4">合作伙伴</el-menu-item>
            <el-menu-item index="5">关于我们</el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <div v-else class="mobileMenu">
      <div class="logo">
        <img :src="logoIcon">
      </div>
      <div>
        <el-popover
            placement="bottom"
            title=""
            width="30"
            popper-class="mobilePop"
            trigger="manual"
            v-model="isShowMenu">
           <div style="max-height: 80vh;overflow-y: scroll;overflow-x: hidden">
             <el-menu
                 :default-active="activeIndex"
                 @select="handleSelect">
               <el-menu-item index="1">
                 <span slot="title">首页</span>
               </el-menu-item>
               <el-submenu index="2">
                 <template slot="title">
                   <span>解决方案</span>
                 </template>
                 <el-menu-item-group>
                   <el-menu-item index="2-1">智慧盐田</el-menu-item>
                   <el-menu-item index="2-2">智慧环卫</el-menu-item>
                   <el-menu-item index="2-3">智慧园区</el-menu-item>
                   <el-menu-item index="2-4">智慧景区</el-menu-item>
                   <el-menu-item index="2-5">智慧校园</el-menu-item>
                 </el-menu-item-group>
               </el-submenu>
               <el-submenu index="3">
                 <template slot="title">
                   <span>产品中心</span>
                 </template>
                 <el-menu-item-group>
                   <el-menu-item index="3-1">公网对讲机</el-menu-item>
                   <el-menu-item index="3-2">无线自组网终端</el-menu-item>
                   <el-menu-item index="3-3">智能操控板</el-menu-item>
                 </el-menu-item-group>
               </el-submenu>
               <el-menu-item index="4">
                 <span slot="title">合作伙伴</span>
               </el-menu-item>
               <el-menu-item index="5">
                 <span slot="title">关于我们</span>
               </el-menu-item>
             </el-menu>
           </div>
          <div slot="reference" @click.stop="isShowMenu = !isShowMenu" :class="[!isShowMenu?'menuShow':'menuHide']"></div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topHeader",
  data(){
    return {
      activeIndex:"1",
      logoIcon:require("../assets/images/logo.png"),
      logoIconBlack:require("../assets/images/logoBlack.png"),
      topTheme:'black',
      isShowMenu:false
    }
  },
  mounted() {
    this.$bus.$on('changeHeaderTheme',this.changeTheme)
    this.$bus.$on('changeTap',this.changeTap)
    document.addEventListener('click',(e)=>{
      this.isShowMenu=false;
    });
  },
  methods:{
    changeTheme(topTheme){
      if(!this.$util.isMobile()){
        this.topTheme=topTheme;
      }
    },
    changeTap(index){
      this.activeIndex=index;
    },
    handleSelect(index){
      this.activeIndex=index;
      switch (index){
        case "1":{
          this.changeTheme('black');
          this.$router.push('/');
          this.goAnchor(0);
          break;
        }
        case "2":
        {
          this.changeTheme('white');
          this.$router.push('solution');
          break;
        }
        case "2-1":
        {
          this.changeTheme('white');
          this.$router.push({ path: 'solution', query: { tabIndex: "1"} });
          this.$bus.$emit('changeSolutionTap','1');
          break;
        }
        case "2-2":
        {
          this.changeTheme('white');
          this.$router.push({ path: 'solution', query: { tabIndex: "5"} });
          this.$bus.$emit('changeSolutionTap','5');
          break;
        }
        case "2-3":
        {
          this.changeTheme('white');
          this.$router.push({ path: 'solution', query: { tabIndex: "2"} });
          this.$bus.$emit('changeSolutionTap','2');
          break;
        }
        case "2-4":
        {
          this.changeTheme('white');
          this.$router.push({ path: 'solution', query: { tabIndex: "3"} });
          this.$bus.$emit('changeSolutionTap','3');
          break;
        }
        case "2-5":
        {
          this.changeTheme('white');
          this.$router.push({ path: 'solution', query: { tabIndex: "4"} });
          this.$bus.$emit('changeSolutionTap','4');
          break;
        }
        case "3":
        {
          this.changeTheme('white');
          this.$router.push('productCenter');
          break;
        }
        case "3-1":
        {
          this.changeTheme('white');
          this.$router.push({ path: 'productCenter', query: { type: "product1"} });
          this.$bus.$emit('changeProductTap','product1');
          break;
        }
        case "3-2":
        {
          this.changeTheme('white');
          this.$router.push({ path: 'productCenter', query: { type: "product2"} });
          this.$bus.$emit('changeProductTap','product2');
          break;
        }
        case "3-3":
        {
          this.changeTheme('white');
          this.$router.push({ path: 'productCenter', query: { type: "product3"} });
          this.$bus.$emit('changeProductTap','product3');
          break;
        }
        case "4":{
          this.changeTheme('white');
          this.$router.push('partner');
          break;
        }
        case "5":{
          this.changeTheme('white');
          this.$router.push('aboutUs');
          break;
        }
      }
      this.isShowMenu=false;
    },
    goAnchor(index) {
      this.$bus.$emit('changeHomeTap',index)
    }
  },
  beforeDestroy() {
    this.$bus.$off('changeHeaderTheme',this.changeTheme)
  }
}
</script>

<style lang="scss" scoped>
.topContent{
  display: flex;
  justify-content: center;
  background-image: linear-gradient(#000,transparent);
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: background-color .3s;
  .el-menu{
    transition: background-color .2s;
  }
  .content{
    display: flex;
    justify-content: space-between;
    width: 12rem;
    min-width: 12rem;
    height: .8rem;
    .logo{
      padding-left: .7rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      img{
        height: .38rem;
      }
    }
    .menu{
      display: flex;
      padding-right: .45rem;
    }
  }
}
.whiteTheme{
  .topContent{
    background-color: transparent;
    background-image: none;
    background-color: #FFFFFF;
    border-bottom: none;
  }
}
.el-button{
  padding-left: .6rem;
  padding-right: .6rem;
}
.mobileMenu{
  height: .5rem;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo{
    padding-left: .16rem;
    img{
      height: .26rem;
    }
  }
  .menuShow{
    width: .2rem;
    height: .2rem;
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    background-image: url("/src/assets/images/menu.png");
    cursor: pointer;
    margin-right: .16rem;
  }
  .menuHide{
    width: .2rem;
    height: .2rem;
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
    background-image: url("/src/assets/images/menuHide.png");
    cursor: pointer;
    margin-right: .16rem;
  }
}
</style>
