<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  beforeMount() {
    if(this.$util.isMobile()){
      document.getElementsByTagName('html')[0].style.fontSize='6.26rem';
      document.getElementsByTagName('body')[0].style.fontSize='.12rem';
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
