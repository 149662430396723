import {
    Menu,
    MenuItem,
    Tabs,
    TabPane,
    Carousel,
    CarouselItem,
    Image,
    Dialog,
    Button,
    Form,
    FormItem,
    Input,
    Select,
    Option,
    Message,
    Popover,
    Submenu,
    MenuItemGroup
} from 'element-ui';

const element = {
    install: function (Vue) {
        Vue.use(Menu);
        Vue.use(MenuItem);
        Vue.use(Tabs);
        Vue.use(TabPane);
        Vue.use(Carousel)
        Vue.use(CarouselItem)
        Vue.use(Image)
        Vue.use(Dialog)
        Vue.use(Button)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Input)
        Vue.use(Select)
        Vue.use(Option)
        Vue.use(Popover)
        Vue.use(Submenu)
        Vue.use(MenuItemGroup)
        Vue.prototype.$message = Message;
    }
}

export default element
