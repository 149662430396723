import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from './util/element'

import 'element-ui/lib/theme-chalk/index.css'

import './assets/theme/index.scss'
// 引入去除浏览器css差异文件
import './assets/scss/tfioe-reset.scss';
// 引入该项目的全局公共样式
import './assets/scss/tfioe-global.scss';
// 局部公共样式
import './assets/scss/tfioe-public.scss';

import api from './api';
import validate from './util/validate';
import common from './util/common'

Vue.use(ElementUI)

Vue.prototype.$bus = new Vue();
Vue.prototype.$api = api;
Vue.prototype.$validate=validate;
Vue.prototype.$util=common;

Vue.config.productionTip = false;

router.beforeEach((to, from,next) => {
   console.log(from.name+'--To--'+to.name);
   next();
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
