import _axios from 'axios';
import {Message} from 'element-ui';
import qs from 'qs';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

const axios = _axios.create();
axios.defaults.timeout = 1000 * 60 * 2;
axios.defaults.baseURL = '/tfioe';
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
// NProgress Configuration
NProgress.configure({
    showSpinner: false,
});
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500 // 默认的
}

// HTTPrequest拦截
axios.interceptors.request.use(config => {
    // 如果不需要头部进度的情况下需传progress参数，例如轮询接口等
    if (config.url.includes('progress=1')) {
        NProgress.done()
        config.url = config.url.replace('&progress=1', '');
        config.url = config.url.replace('progress=1', '');
    } else if (config.data && config.data.progress) {
        NProgress.done();
        delete config.data.progress
    } else {
        NProgress.start() // start progress bar
    }

    config.headers['entIdent'] = window.document.location.host.split(':')[0]
    config.headers['Authorization'] = 'Basic ' + 'dGVzdDp0ZXN0';

    // headers中配置serialize为true开启序列化
    if (config.methods === 'post' && config.headers.serialize) {
        config.data = this.node.serialize(config.data)
        delete config.data.serialize
    }

    // 处理get 请求的数组 springmvc 可以处理
    if (config.method === 'get') {
        config.paramsSerializer = function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }

    return config
}, error => {
    return Promise.reject(error)
})

let msg = null;
// HTTPresponse拦截
axios.interceptors.response.use(res => {
    NProgress.done();
    const status = Number(res.status) || 200;

    if (res.status === 200) {
        console.log("dddd")
        if (res.data.code === 0) {
            if (res.data&&res.data!=null) {
                return Promise.resolve(res.data);
            } else {
                return Promise.resolve(null);
            }
        } else {
            msg && msg.close();
            msg = Message({
                message: res.data.msg || "请求异常",
                type: 'error'
            })
            return Promise.resolve(null);
        }
    } else if (status === 500) {
        msg = Message({
            message: "网络出现异常，请检查您的网络连接后重试",
            type: 'error'
        })
        return Promise.reject('')
    } else {
        msg = Message({
            message: "网络出现异常，请检查您的网络连接后重试",
            type: 'error'
        })
        return Promise.reject('')
    }
}, error => {
    NProgress.done()
    msg && msg.close();
    Message({
        message: "网络出现异常，请检查您的网络连接后重试",
        type: 'error'
    })
    return Promise.reject(error)
})


export default axios;
