// 手机号校验
const validatePhone = (rule, value, callback) => {
    console.log(value)
    if (value) {
        console.log("dd");
        if (!/^([1][3,4,5,6,7,8,9][\d]{9}|[1][3,4,5,6,7,8,9][\d]{11})$/.test(value)) {
            callback(new Error("手机号码格式错误"));
        } else {
            callback();
        }
    } else {
        if(rule.required) {
            callback(new Error("请输入手机号"));
        }else {
            callback();
        }
    }
}

export default {
    validatePhone
}
