import axios from '../../util/axios';

/** 发送邮件 */
const sendCorporationEmail=(data) => {
    return axios.post(`/consumer/mobile/sendCorporationEmail`,data)
}

export default {
    sendCorporationEmail
}
