<template>
  <div class="layout-warp">
    <TopHeader></TopHeader>
    <keep-alive :include="keepAliveRoutes.join(',')">
      <router-view :class="[this.$util.isMobile()?'router-view-warp-mobile':'router-view-warp']"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import TopHeader from "./topHeader";
export default {
  name: "BasicLayout",
  data() {
    return {
      activeIndex: '/index',
      keepAliveRoutes: []
    };
  },
  computed: {},
  created() {
    this.activeIndex = this.$route.path;
  },
  methods: {
    changeTab(arr) {
      this.activeIndex = this.$route.path;
      this.$nextTick(()=>{
        this.keepAliveRoutes = [];
        arr.map(v=>{
          this.keepAliveRoutes.push(v.url.replace('/',''));
        })
      })
    }
  },
  components: {
    TopHeader
  },
  beforeDestroy() {

  },
}
</script>

<style lang="scss" scoped>
.layout-warp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .router-view-warp{
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100vh;
  }
  .router-view-warp-mobile{
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: calc(100vh - .5rem);
  }
}
</style>
