import Vue from 'vue';
import VueRouter from 'vue-router';
import BasicLayout from '../BasicLayout';
Vue.use(VueRouter)

// 兼容IE
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

const routes = [
    {
        path: '/',
        component: BasicLayout,
        children: [
            //首页
            {
                path: '/',
                name: "home",
                meta: {
                    i18n: '首页',
                    requireAuth: true
                },
                component: () => import('@/views/home'),
            },
            //产品中心
            {
                path: '/productCenter',
                name: "productCenter",
                meta: {
                    i18n: '产品中心',
                    requireAuth: true
                },
                component: () => import('@/views/productCenter'),
            },
            //解决方案
            {
                path: '/solution',
                name: "solution",
                meta: {
                    i18n: '解决方案',
                    requireAuth: true
                },
                component: () => import('@/views/solution'),
            },
            //合作伙伴
            {
                path: '/partner',
                name: "partner",
                meta: {
                    i18n: '合作伙伴',
                    requireAuth: true
                },
                component: () => import('@/views/partner'),
            },
            //关于我们
            {
                path: '/aboutUs',
                name: "aboutUs",
                meta: {
                    i18n: '关于我们',
                    requireAuth: true
                },
                component: () => import('@/views/aboutUs'),
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
